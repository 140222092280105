body {
  margin: 0;
}
p {
  line-height: 24px;
  font-weight: 400;
}

h1 {
  color: black !important;
}

label > span {
  font-size: 14px;
}

.active {
  border-left: 4px solid #6f58c4;
}
